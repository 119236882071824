<template>
  <el-button size="mini" type="primary" @click="showUploadAction">
    批量导入
    <el-dialog
      append-to-body
      title="上传Excel"
      :visible.sync="showUpload"
      width="480px"
      @close="close"
    >
      <div class="upload_body">
        <el-upload
          accept=".xlsx,.xls"
          action="#"
          :auto-upload="false"
          :file-list.sync="fileList"
          :limit="1"
          :on-change="changeFile"
          :on-remove="removeFile"
        >
          <el-button slot="trigger" type="primary">选取文件</el-button>
          <div slot="tip" class="upload_tip">
            请导出模版，按照模板格式填写后导入。
            <span><a :href="address" target="_blank">下载导入模版</a></span>
          </div>
        </el-upload>
      </div>
      <template #footer>
        <el-button @click="close">关闭</el-button>
        <el-button :loading="isLoading" type="primary" @click="save">
          确 定
        </el-button>
      </template>
    </el-dialog>
  </el-button>
</template>
<script>
  export default {
    props: {
      downUrl: {
        type: String,
        default: '',
      },
      uploadAciton: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        address: process.env.VUE_APP_DOWN + this.downUrl,
        fileList: [],
        isLoading: false,
        showUpload: false,
      }
    },
    methods: {
      async getList() {},
      close() {
        this.showUpload = false
      },
      removeFile() {
        this.fileList = []
      },
      changeFile(file) {
        this.fileList = [file.raw]
      },
      async save() {
        if (this.fileList.length === 0) {
          this.$baseMessage('请选择文件', 'error', 'vab-hey-message-success')
          return
        }
        this.isLoading = true
        let form = new FormData()
        form.append('file', this.fileList[0])
        await this.uploadAciton(form)
        this.isLoading = false
        this.fileList = []
        this.close()
      },
      showUploadAction() {
        this.showUpload = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .upload_body {
    text-align: left;
  }
  .upload_tip {
    margin-top: 10px;
    color: red;
  }
</style>
